import { h, FunctionComponent } from 'preact';
import { PAGES } from '../../core/Navigation/Pages';
import * as classes from './Footer.module.css';
import Container from '../Container/Container';

const Footer: FunctionComponent = () => {
    return (
        <Container>
            <footer className={classes.footer}>
                <ul className={classes.footerList}>
                    <li>
                        <a href={PAGES.IMPRESSUM}>Impressum</a>
                        <a
                            href={PAGES.DATENSCHUTZ}
                            className={classes.className2}
                        >
                            Datenschutz
                        </a>
                    </li>

                    <li>© 2024 Designstudio Jessica Beckmann</li>
                </ul>
            </footer>{' '}
        </Container>
    );
};

export default Footer;
