import { h } from 'preact';
import Router from 'preact-router';
import AsyncRoute from 'preact-async-route';
import { PAGES } from './Pages';
import LoadingSpinner from 'src/components/LoadingSpinner/LoadingSpinner';

const HOME = () => import('../../pages/Home').then((module) => module.default);
const ANGEBOT = () =>
    import('../../pages/Angebot').then((module) => module.default);
const BIO = () => import('../../pages/Bio').then((module) => module.default);
const IMPRESSUM = () =>
    import('../../pages/Impressum').then((module) => module.default);
const DATENSCHUTZ = () =>
    import('../../pages/Datenschutz').then((module) => module.default);
const KONTAKT = () =>
    import('../../pages/Kontakt').then((module) => module.default);
const PROJECT_OVERVIEW = () =>
    import('../../pages/ProjectOverview').then((module) => module.default);

const PROJECT_DETAIL = () =>
    import('../../pages/ProjectDetail').then((module) => module.default);

const Routes = () => {
    const onChange = (arg: any) => {
        window.__jess = arg?.path ?? '';

        if (typeof window.scrollTo === 'function') {
            window.scrollTo({ top: 0, left: 0 });
        }
    };

    return (
        <Router onChange={onChange}>
            <AsyncRoute
                path={PAGES.HOME}
                getComponent={HOME}
                loading={() => <LoadingSpinner />}
            />

            <AsyncRoute
                path={PAGES.ANGEBOT}
                getComponent={ANGEBOT}
                loading={() => <LoadingSpinner />}
            />

            <AsyncRoute
                path={PAGES.BIO}
                getComponent={BIO}
                loading={() => <LoadingSpinner />}
            />

            <AsyncRoute
                path={PAGES.IMPRESSUM}
                getComponent={IMPRESSUM}
                loading={() => <LoadingSpinner />}
            />

            <AsyncRoute
                path={PAGES.DATENSCHUTZ}
                getComponent={DATENSCHUTZ}
                loading={() => <LoadingSpinner />}
            />

            <AsyncRoute
                path={PAGES.KONTAKT}
                getComponent={KONTAKT}
                loading={() => <LoadingSpinner />}
            />

            <AsyncRoute
                path={PAGES.PROJECT_OVERVIEW}
                getComponent={PROJECT_OVERVIEW}
                loading={() => <LoadingSpinner />}
            />

            <AsyncRoute
                path={`${PAGES.PROJECT_OVERVIEW}:project`}
                getComponent={PROJECT_DETAIL}
                loading={() => <LoadingSpinner />}
            />
        </Router>
    );
};

export default Routes;
