import { h, FunctionComponent, Fragment } from 'preact';

import logo from './images/logo-designstudio.svg';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Routes from './core/Navigation/Routes';

const App: FunctionComponent = () => (
    <Fragment>
        <Header />

        <main>
            <Routes />
        </main>

        <Footer />
    </Fragment>
);

export default App;
