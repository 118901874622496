.hamburger {
    width: 30px;
    height: 30px;
    position: relative;
    z-index: var(--z-highest);
    margin-top: 10px;
    margin-right: 3px;

}

@media (width >= 600px) {
    .hamburger {
        display: none;
    }
}

.hamburger .bar {
    padding: 0;
    width: 30px;
    height: 3px;
    background-color: var(--text-color);
    display: block;
    border-radius: 4px;
    transition: all 0.4s ease-in-out;
    position: absolute;
}

.hamburger .bar1 {
    transform-origin: 5%;
}

.hamburger .bar4 {
    transform-origin: 5%;
}



.active.hamburger>.bar1 {
    transform: rotate(45deg);
    height: 3px;
    width: 42px;
}

.active.hamburger>.bar3 {
    transform: rotate(45deg);
    height: 3px;
    background-color: transparent;
}

.active.hamburger>.bar2 {
    transform: rotate(-45deg);
    height: 3px;
    background-color: transparent;
}

.active.hamburger>.bar4 {
    transform: rotate(-45deg);
    height: 3px;
    width: 42px;
}

.bar1 {
    top: 0;
}

.bar2,
.bar3 {
    top: 13.5px;
}

.bar3 {
    right: 0;
}

.bar4 {
    bottom: 0;
}