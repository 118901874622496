import { FunctionComponent, h } from 'preact';
import * as classes from './BurgerIcon.module.css';

interface IProps {
    onClick: () => void;
    isOpen: boolean;
}

const BurgerIcon: FunctionComponent<IProps> = (props) => {
    const activeClass = props.isOpen ? classes.active : '';

    return (
        <div
            className={`${classes.hamburger} ${activeClass}`}
            onClick={props.onClick}
        >
            <span className={`${classes.bar} ${classes.bar1} `}></span>
            <span className={`${classes.bar} ${classes.bar2} `}></span>
            <span className={`${classes.bar} ${classes.bar3} `}></span>
            <span className={`${classes.bar} ${classes.bar4} `}></span>
        </div>
    );
};

export default BurgerIcon;
